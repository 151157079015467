<template>
  <footer>
    <cookie-law
      ref="cookieBanner"
      class="secondary white--text"
      :storage-name="storageName"
      @accept="$emit('accepted')"
      @decline="$emit('declined')"
      @revoke="$emit('revoked')"
    >
      <v-row class="align-center justify-space-between flex-md-nowrap">
        <v-col class="shrink" cols="12" md="auto">
          <span class="mr-2">{{ $t('cookieLayer.text') }}</span>
          <app-link-btn
            v-if="url"
            :href="url"
            color="white"
            target="_blank"
            title="cookieLayer.learnMore"
            variant="muted"
          />
        </v-col>
        <v-col class="shrink" cols="12" md="auto">
          <v-row :dense="isMobile" :class="{ 'flex-nowrap': !isMobile }" justify="center">
            <v-col cols="auto">
              <app-btn
                class="white--text"
                color="secondary"
                title="cookieLayer.decline"
                @click="declineCookies"
              />
            </v-col>
            <v-col cols="auto">
              <app-btn title="cookieLayer.accept" color="primary" @click="acceptCookies" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </cookie-law>
  </footer>
</template>

<script>
import CookieLaw from 'vue-cookie-law';
import { COOKIE_STORAGE } from '@/statics/storageKeys';

export default {
  name: 'CookieLayer',

  components: { CookieLaw },

  computed: {
    url() {
      if (!this.product) {
        return;
      }
      return this.product.privacyPolicyUrl;
    },
    storageName() {
      return COOKIE_STORAGE;
    }
  },

  methods: {
    acceptCookies() {
      this.$refs.cookieBanner.accept();
      this.$eventHub.$emit('cookies-set', true);
    },

    declineCookies() {
      this.$refs.cookieBanner.decline();
      this.$eventHub.$emit('cookies-set', false);
    }
  },

  mounted() {
    this.$eventHub.$on('cookies-revoked', () => this.$refs.cookieBanner.revoke());
    this.$on('hook:beforeDestroy', () => {
      this.$eventHub.$off('cookies-revoked');
    });
  }
};
</script>

<style scoped>
>>> div {
  word-break: keep-all !important;
}

.Cookie > * {
  margin: 0;
}

.Cookie--base {
  padding: 0 2rem;
}
</style>
