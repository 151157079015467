<template>
  <div class="d-flex fill-height">
    <app-loading-sheet v-if="loading || !$auth.user()" />
    <router-view v-else :key="current" />
    <cookie-layer
      @accepted="initiateAnalytics"
      @declined="stopAnalytics"
      @revoked="stopAnalytics"
    />
  </div>
</template>

<script>
const SITE_IMPROVE_ANALYTICS_URL = '//siteimproveanalytics.com/js/siteanalyze_6012580.js';

import CookieLayer from '@/shared/components/CookieLayer';
import OptInStatus from '@/modules/Authentication/statics/OptInStatus';
import Role from '@/statics/role';
import UserInfoMixin from '@/mixins/UserInfoMixin';
import { NAMESPACE as AUTHENTICATION_NAMESPACE } from '@/modules/Authentication/store';
import { NAMESPACE as USER_NAMESPACE } from '@/modules/User/store';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'Authorized',

  components: {
    CookieLayer
  },

  mixins: [UserInfoMixin],

  data: () => ({
    userInfoInterval: null,
    redirectToUserInfo: false,
    loading: false
  }),

  computed: {
    ...mapState(AUTHENTICATION_NAMESPACE, ['current', 'loadingCurrent', 'storedInfoDialogs']),

    userInfoActions() {
      return [
        {
          action: this.fetchUnhandledWorkQueueCount,
          condition: this.permissions.navigation[this.routeName.WORK_QUEUE]
        },
        {
          action: this.fetchUnreadNotificationsCount,
          condition: this.permissions.notifications.show
        }
      ];
    }
  },

  methods: {
    ...mapMutations(AUTHENTICATION_NAMESPACE, {
      getStoredInfoDialogs: 'GET_STORED_INFO_DIALOGS',
      setStoredInfoDialogs: 'SET_STORED_INFO_DIALOGS'
    }),

    ...mapActions(USER_NAMESPACE, [
      'fetchCreditorPermissions',
      'fetchUnhandledWorkQueueCount',
      'fetchUnreadNotificationsCount'
    ]),

    initiateUserData() {
      if (!this.current) {
        this.setCurrent(this.$auth.user().id);
      }

      if (!this.$auth.check(Role.ADMIN)) {
        this.fetchCreditorPermissions();
      }

      this.fetchUserInfoData();

      this.userInfoInterval = setInterval(() => {
        this.checkCurrentUser();
        this.fetchUserInfoData();
      }, 60000);

      this.setAdminView(window.localStorage.getItem('dvkmu_admin_view') === 'true');
    },

    async fetchUserInfoData() {
      await Promise.all(
        this.filterItemsWithFulfilledCondition(this.userInfoActions).map((action) =>
          action.action()
        )
      );

      if (!this.$auth.check()) {
        return this.logout();
      }
    },

    async checkCurrentUser() {
      this.$http
        .get('user-management/user/current')
        .then(async ({ data }) => {
          if (data.id !== this.current) {
            this.$eventHub.$emit('current-changed');
          }

          if (!this.isAdmin && data.optIn !== OptInStatus.ACCEPTED) {
            await this.$auth.fetch({});
            this.$router.push({ name: this.routeName.OPT_IN });
          }
        })
        .catch(() => {});
    },

    initiateUserInfoDialogs() {
      this.getStoredInfoDialogs();
      this.cleanUpRemovedDialogs();

      const notAllDialogsStored = this.activeInfoDialogs.some(
        (dialog) => !this.storedInfoDialogs.includes(dialog.key)
      );

      if (
        !this.$auth.check(this.role.ADMIN) &&
        notAllDialogsStored &&
        this.$route.name !== this.routeName.USER_INFO
      ) {
        this.redirectToUserInfo = true;
      }
    },

    cleanUpRemovedDialogs() {
      const keyList = this.infoDialogs.map((dialog) => dialog.key);
      const currentInfoDialogs = [
        ...this.storedInfoDialogs.filter((storedKey) => keyList.includes(storedKey))
      ];

      if (currentInfoDialogs.length < this.storedInfoDialogs.length) {
        this.setStoredInfoDialogs(currentInfoDialogs);
      }
    },

    async checkRedirectToUserInfo() {
      if (
        this.redirectToUserInfo &&
        this.$route.name !== this.routeName.UPDATE_PASSWORD &&
        this.$route.name !== this.routeName.OPT_IN &&
        this.$route.name !== this.routeName.USER_INFO
      ) {
        await this.$router.push({ name: this.routeName.USER_INFO });
        this.redirectToUserInfo = false;
      }
    },

    initiateAnalytics() {
      if (window._sz || this.isAdmin) {
        return;
      }

      const analyticsScript = document.createElement('script');
      analyticsScript.type = 'text/javascript';
      analyticsScript.async = true;
      analyticsScript.src = SITE_IMPROVE_ANALYTICS_URL;
      document.body.appendChild(analyticsScript);
    },

    stopAnalytics() {
      const analyticsScript = Array.from(document.getElementsByTagName('script')).find((script) =>
        script.src.includes(SITE_IMPROVE_ANALYTICS_URL)
      );

      if (!analyticsScript) {
        return;
      }

      document.body.removeChild(analyticsScript);
      window._sz = undefined;
    }
  },

  async created() {
    this.loading = true;
    if (!this.$auth.user()) {
      return;
    }

    const hasNoPermissionToAccessRoute =
      this.permissions.navigation[this.$route.name] === false ||
      this.routes[this.$route.name]?.condition === false;

    const isAdminAccessByDeeplink = this.isAdmin && !this.$route.redirectedFrom;

    if (hasNoPermissionToAccessRoute && !isAdminAccessByDeeplink) {
      await this.$router.replace({ name: this.defaultRoute.route });
    }

    this.initiateUserData();
    this.initiateUserInfoDialogs();
    await this.checkRedirectToUserInfo();
    this.loading = false;
  },

  updated() {
    this.checkRedirectToUserInfo();
  },

  beforeDestroy() {
    clearInterval(this.userInfoInterval);
    this.stopAnalytics();
  },

  beforeRouteUpdate(to, from, next) {
    if (this.isAdmin) {
      return next(true);
    }

    if (
      this.permissions.navigation[to.name] === false ||
      this.routes[to.name]?.condition === false
    ) {
      return this.$router.replace({ name: this.defaultRoute.route });
    }

    next(true);
  }
};
</script>
